import { Component, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@blisterr/components/fullscreen';
import { FuseLoadingBarComponent } from '@blisterr/components/loading-bar';
import {
    FuseHorizontalNavigationComponent,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@blisterr/components/navigation';
import { FuseMediaWatcherService } from '@blisterr/services/media-watcher';
import { NavigationService } from '@core/navigation/navigation.service';
import { map } from 'rxjs';
import { LanguagesComponent } from '../../../common/languages/languages.component';
import { MessagesComponent } from '../../../common/messages/messages.component';
import { NotificationsComponent } from '../../../common/notifications/notifications.component';
import { QuickChatComponent } from '../../../common/quick-chat/quick-chat.component';
import { SearchComponent } from '../../../common/search/search.component';
import { ShortcutsComponent } from '../../../common/shortcuts/shortcuts.component';
import { UserComponent } from '../../../common/user/user.component';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FuseLoadingBarComponent,
        FuseVerticalNavigationComponent,
        FuseHorizontalNavigationComponent,
        MatButtonModule,
        MatIconModule,
        LanguagesComponent,
        FuseFullscreenComponent,
        SearchComponent,
        ShortcutsComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        RouterOutlet,
        QuickChatComponent,
    ],
})
export class ModernLayoutComponent {
    private _navigationService = inject(NavigationService);
    private _fuseMediaWatcherService = inject(FuseMediaWatcherService);
    private _fuseNavigationService = inject(FuseNavigationService);

    navigation = toSignal(this._navigationService.navigation$);
    isScreenSmall = toSignal(
        this._fuseMediaWatcherService.onMediaChange$.pipe(
            map(({ matchingAliases }) => !matchingAliases.includes('md'))
        )
    );

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
