import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
    HttpResponseBase,
} from '@angular/common/http';
import { environment } from '@environments';
import { Observable, mergeMap, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const CODEMESSAGE: { [key: number]: string } = {
    200: 'The server successfully returned the requested data.',
    201: 'New or modified data is completed.',
    202: 'A request has entered the background queue (asynchronous task).',
    204: 'The data was deleted successfully.',
    400: 'There was an error in the request sent, and the server did not create or modify data.',
    401: 'The user does not have permission (the token, username, password is wrong).',
    403: 'The user is authorized, but access is forbidden.',
    404: 'The request sent was for a record that did not exist, and the server did not operate.',
    406: 'The requested format is not available.',
    410: 'The requested resource is permanently deleted and will no longer be available.',
    422: 'When creating an object, a validation error occurred.',
    500: 'An error occurred in the server, please check the server.',
    502: 'Gateway error.',
    503: 'The service is unavailable, and the server is temporarily overloaded or maintained.',
    504: 'The gateway timed out.',
};

export function defaultInterceptor(
    req: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
    let url = req.url;
    if (
        !url.startsWith('https://') &&
        !url.startsWith('http://') &&
        url.startsWith('/api')
    ) {
        url = url.replace('/api', environment.api.baseUrl);
        let newReq = req.clone({ url });

        return next(newReq).pipe(
            mergeMap((ev) => {
                if (ev instanceof HttpResponseBase) {
                    return handleData(ev);
                }
                return of(ev);
            }),
            catchError((err: HttpErrorResponse) => handleData(err))
        );
    }

    return next(req);
}

function checkStatus(ev: HttpResponseBase): void {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
        return;
    }

    const errorText = CODEMESSAGE[ev.status] || ev.statusText;
    const error = (ev as HttpErrorResponse).error;

    console.warn(`${ev?.status}: ${errorText}`, error?.message);
}

function expiredSession(ev: HttpResponseBase): Observable<any> {
    return throwError(() => ev);
}

function handleData(ev: HttpResponseBase): Observable<any> {
    checkStatus(ev);
    switch (ev.status) {
        case 200:
            break;
        case 401:
            return expiredSession(ev);
        case 403:
        case 404:
        case 500:
            // TODO this.goTo(`/exception/${ev.status}`);
            break;
        default:
            if (ev instanceof HttpErrorResponse) {
                console.warn('Error', ev);
            }
    }
    if (ev instanceof HttpErrorResponse) {
        return throwError(() => ev);
    } else {
        return of(ev);
    }
}
