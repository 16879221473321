import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './modules/home/home.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            { path: '', component: HomeComponent },
            {
                path: 'payment-intent',
                loadChildren: () =>
                    import(
                        './modules/payment-intent/payment-intent.module'
                    ).then((m) => m.PaymentIntentModule),
            },
            {
                path: 'connect',
                loadChildren: () =>
                    import('./modules/connect/connect.module').then(
                        (m) => m.ConnectModule
                    ),
            },
        ],
    },
];
